<template>
  <div class="wrapper">
    <b-card bg-variant="dark">
      <div class="animated fadeIn">
        <!-- xxxxxxxxxxxxxxxxxxxxxxxxxx -->
        <b-row>
          <b-col sm="12" md="6">
            <img slot="img" class="d-block img-fluid w-100" width="1024" height="480" src="../../../static/img/people/1.png" alt="image slot">
            <b-row>
              <b-col cols="6">
                <a href="https://www.facebook.com/Dr.Levent.Korkmaz" style="margin-right: 10px;"><i class="fa fa-facebook"></i></a>
                <a href="https://twitter.com/dr_l_korkmaz" style="margin-right: 10px;"><i class="fa fa-twitter"></i></a>
                <a href="https://www.instagram.com/drleventkorkmaz/" style="margin-right: 10px;"><i class="fa fa-instagram"></i></a>
                <a href="https://www.linkedin.com/in/drleventkorkmaz/" style="margin-right: 10px;"><i class="fa fa-linkedin"></i></a>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12" md="6">
            <b-row style="margin-top: 10px;">
              <b-col cols="12">
                <h2 style="color: white;">{{ $t('wdm16.11039') }}</h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <span style="color: white">
                <p>{{ $t('wdm16.11040') }}</p>

                <p>{{ $t('wdm16.11041') }}</p>
                <p>
                  <img src="@/icon/110598.png" style="border-radius: 10px; width: 40px;">  {{ $t('wdm16.36') }} :
                  <a href="mailto:lkorkmaz@wisdomera.io?Subject=''" target="_top">lkorkmaz@wisdomera.io</a>
                </p>
                <p>
                  <img src="@/icon/2910059.png" style="border-radius: 10px; width: 40px;">
                  <strong> {{ $t('wdm16.11042') }} </strong>
                  <span>{{ $t('wdm16.11043') }}</span>
                </p>
                <p>
                  <img src="@/icon/672026.png" style="border-radius: 10px; width: 40px;">
                  <strong>{{ $t('wdm16.11044') }}</strong>
                  <span>{{ $t('wdm16.11045') }}</span>
                </p>
                </span>
              </b-col>
            </b-row>
            <!-- xxxxxxxxxxxxxxxxxxxxxxxxxxxxx -->
          </b-col>
        </b-row>
        <br>
        <b-row>
          <b-col sm="12" md="12">
            <span style="color: white;">
            <p>{{ $t('wdm16.11046') }}</p>
            <p>{{ $t('wdm16.11047') }}</p>
            <p>{{ $t('wdm16.11048') }}</p>
            <p>{{ $t('wdm16.11049') }}</p>
          <p>
            <i>{{ $t('wdm16.11050') }}</i>
          </p>
          <p style="text-align: right; margin-right: 10px;">
              <span>Dr Levent Korkmaz</span>
            </p>
            </span>
          </b-col>
        </b-row>
        <br>
        <b-row style="border-top: solid 1px white;">
          <b-col cols="12">
            <h2 style="color: white; padding: 5px;">
            <!-- <img src="@/icon/2249810.png" style="border-radius: 10px; width: 40px;"> -->
            {{ $t('wdm16.11051') }}
          </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="4">
            <img slot="img" class="d-block img-fluid w-100" width="1024" height="480" src="../../../static/img/office/wisdomeraoffice1.jpeg" alt="image slot">
          </b-col>
          <b-col sm="12" md="8">
            <b-row>
              <b-col cols="12">
                <span style="color: white">
                <p>{{ $t('wdm16.11052') }}
                </p>
                <p>{{ $t('wdm16.11053') }}
                </p>
              <p>
                <img src="@/icon/1661274.png" style="border-radius: 10px; width: 40px;">  {{ $t('wdm16.20') }} 
                <a href="https://www.google.com/maps/dir/41.0362587,29.1587105/google+maps+wisdomera/@41.0566366,28.8752767,11z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14cabbce9c31f443:0xdbb0a69fe9a80fe5!2m2!1d28.9118955!2d41.0164703" target="_target">WisdomEra A.Ş, MERKEZEFENDİ MAHALLESİ, Eski Londra Asfaltı Cd. NO:11, 34015 Zeytinburnu/İstanbul</a>
              </p>
              <p>
                <img src="@/icon/1047163.png" style="border-radius: 10px; width: 40px;">
                <strong>  {{ $t('wdm16.35') }}  </strong>
                <span><a href="tel:02124151093">02124151093</a> </span>
                </p>
                </span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <br>
        <web-bottom></web-bottom>
      </div>
    </b-card>
  </div>
</template>

<script>
import WebBottom from '@/components/widgets/WebBottom';
import { mapGetters } from 'vuex';
export default {
  name: 'About',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    WebBottom
  },
  props: {},
  data () {
    return {};
  },
  created: function () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style>


</style>

